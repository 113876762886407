.swiper-container{
    @apply w-full lg:h-full bg-gray-400;
    overflow-y:visible !important;

    .swiper-wrapper{
        .cmeleon-block-content:nth-child(2) .home-slide-overlay,
        .cmeleon-block-content:last-child .home-slide-overlay{
            @apply bg-home-slide2overlay;
        }

        .cmeleon-block-content:nth-child(3) .home-slide-overlay{
            @apply bg-home-slide1overlay;
        }

        .cmeleon-block-content:nth-child(4) .home-slide-overlay{
            @apply bg-home-slide3overlay;
        }
    }

    .cmeleon-block-content {
		text-align: center;
		font-size: 18px;
		background: #fff;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;

		img.swiper-slide-image{
            @apply block lg:w-full h-full;
			object-fit: cover;
		}

        img.swiper-slide-corner{
            width:auto;
            height:655px !important;
            top:0;
            right:-335px;
        }
	}

	.swiper-button-prev, .swiper-button-next{
		@apply h-28 w-12 bg-cover bg-top bg-no-repeat md:top-2/3 xl:top-3/4;
	}

	.swiper-button-prev{
		background-image:url('/img/custom/slider-prev-btn.png');
		left:auto !important;
		right:250px !important;
	}

	.swiper-button-prev:after,
	.swiper-button-next:after{
		@apply hidden;
	}

	.swiper-button-next{
		background-image:url('/img/custom/slider-next-btn.png');
		right:150px !important;
	}

	.swiper-pagination-bullets {
        @apply relative bg-gray-100 pt-4 pb-2 z-0 h-16;
        bottom: 0 !important;
    }
}

@media only screen and (min-width: 768px) {
    .swiper-container {
        overflow-y:hidden !important;
    }
}

@media only screen and (max-width: 768px) {
    .swiper-container{
        .swiper-pagination-bullets{
            position: absolute;
            background-color:transparent !important;
            top:-10px;
            z-index: 10!important;
            text-align:center !important;
            padding-left:0;
        }

        .swiper-wrapper{
            .cmeleon-block-content {
                .home-slide-overlay {
                    background-image:none !important;
                }
            }

            .cmeleon-block-content:nth-child(2) .home-slide-overlay,
            .cmeleon-block-content:last-child .home-slide-overlay{
                @apply bg-opacity-60 bg-lightblue;
            }

            .cmeleon-block-content:nth-child(3) .home-slide-overlay{
                @apply bg-opacity-60 bg-green;
            }

            .cmeleon-block-content:nth-child(4) .home-slide-overlay{
                @apply bg-opacity-60 bg-yellow;
            }
        }
    }
}

.bg-home-slide1overlay{
    background-image:url('/img/custom/home-slide1-overlay.png');
}

.aem-intro{
    h1,h2,h3,h4,h5{
        @apply text-2xl font-bold;
    }
}

.aem-disciplinethumb{
    .cmeleon-block{
        position: inherit !important;
    }
}

.cmeleon-region-home-swiper > .cmeleon-block > .cmeleon-button-container{
    @apply absolute bottom-0 left-0;
}

.aem-content2-sub-menu{
    ul{
        @apply list-none p-0;

        li{
            @apply py-3 border-b border-gray-100 border-opacity-50 cursor-pointer text-lg text-white;
        }

        li:last-child{
            @apply border-none;
        }
    }
}


.aem-content2-text1{
    h1,h2,h3,h4,h5{
        @apply mb-8 text-2xl font-bold;
    }

    p{
        @apply text-center;
    }
}

#componenten,
#components,
#komponenten{
    .components-swiper {
        @apply bg-transparent;

        .aem-content2-sub-nav {
            @apply mt-16 block md:flex;

            .cmeleon-block-content {
                @apply lg:w-1/3 px-3 mb-8 md:mb-0 bg-transparent;

                img {
                    @apply h-48;
                }

                > div{
                    @apply h-52;
                }
            }
        }
    }

    .swiper-components-nav{
        @apply absolute w-full;
        margin-top:-360px;

        .swiper-components-button-prev,
        .swiper-components-button-next {
            @apply absolute z-10 h-28 w-12 bg-cover bg-top bg-no-repeat top-1/2 cursor-pointer;
        }

        .swiper-components-button-prev {
            background-image: url('/img/custom/slider-components-prev-btn.png');
            left: 0 !important;
            right: auto !important;
        }

        .swiper-components-button-next {
            background-image: url('/img/custom/slider-components-next-btn.png');
            left: auto !important;
            right: 0 !important;
        }
    }
}

.aem-content2-grid-text{
    h1,h2,h3,h4,h5{
        @apply text-2xl lg:text-4xl font-bold;

        span{
            font-size:inherit !important;
        }

        b{
            font-weight:inherit !important;
        }
    }
}

.content2-grid-image-wrapper{
    .cmeleon-block-content{
        img{
            @apply object-cover lg:h-450px;
        }
    }
}

.note-editing-area ol, .note-editing-area ul, .note-editing-area dl{
    list-style:disc !important;
    margin-left:20px;
}

.aem-highlight,
.aem-highlight1,
.aem-specials-highlight1,
.aem-highlight2{
    .cmeleon-block-content{
        a{
            @apply relative inline-block py-2 pl-5 pr-10 bg-lightblue bg-opacity-75 text-white border border-lightblue rounded-2xl uppercase font-bold text-xs no-underline;
        }

        a:after{
            @apply absolute block w-4 h-4 top-2 right-4 bg-cover;
            background-image:url('/img/custom/svg/arrow-white-btn.svg');
            content:'';
        }
    }
}

.aem-project:hover{
    .project-description{
        @apply block;
    }
}

//html,
//body {
//  @apply text-secondary-500;
//  font-size: 16px;
//  scroll-behavior: smooth;
//
//  @media (min-width: theme('screens.md')) {
//    font-size: 20px;
//  }
//}

.cmeleon-content,
.cmeleon-block-content{
    p{
        font-weight:normal;
    }

    a{
        @apply underline;
    }

    .btn{
        @apply no-underline;
    }

    ul{
        @apply list-disc pl-5;
    }
}

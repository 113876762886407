@mixin svg-size($name) {
  $size: map-get($svg-sizes, $name);

  width: map-get($size, width);
  height: map-get($size, height);
}

@mixin size-modifiers($prefix: '--') {
  @each $key, $value in $svg-sizes {
    $size: map-get($svg-sizes, $key);

    &#{$prefix}#{$key} {
      width: map-get($size, width);
      height: map-get($size, height);
    }
  }
}

.svg {
  @include size-modifiers;
}

@tailwind base;
@tailwind components;

// SVG
@import 'svg/svg';

// Variables
@import 'variables/font';

// Layout
@import 'layouts/footer';
@import 'layouts/header';
@import 'layouts/main';

// Blocks
// Block specific styling.
// @import 'blocks/[name]';

// Regions
// Region specific styling (block overrides per region).
// @import 'regions/[name]';

// Vendors
// Vendor imports and overrides
// @import 'swiper/swiper-bundle';
// @import 'vendors/[name]';

// utility classes
// Create custom utility classes
// @import 'utilities/[name]';

// Pages / templates
@import 'pages/home';
@import 'pages/aboutus';
@import 'pages/content';
@import 'pages/content2';
@import 'pages/content3';
@import 'pages/contact';

@tailwind utilities;

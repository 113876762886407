.aem-aboutus-intro{
    .cmeleon-block-content{
        h1,h2,h3,h4,h5{
            @apply mb-8 text-2xl font-bold;

            span{
                font-size:inherit !important;
            }

            b{
                font-weight:inherit !important;
            }
        }
    }
}
